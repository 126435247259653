/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import { GROUPED_PRODUCTS_GUEST } from '../component/ShopMyInfluence/ShopMyInfluence.config';
import ShopMyInfluenceContainer from '../component/ShopMyInfluence/ShopMyInfluence.container';
import ProductHelper from '../component/ShopMyInfluence/utils/Product';
import ShopMyInfluenceQuery from '../query/ShopMyInfluence.query';

export const handle_syncCartWithBEError = (args, callback) => callback(...args)
    .then(
        (result) => {
            ShopMyInfluenceContainer.getInstance().setGroupedProducts({});
            return result;
        }
    );

export const addShopMyInfluenceConfigQuery = (args, callback) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        ShopMyInfluenceQuery.getShopMyInfluenceConfiguration()
    ];
};

export const addShopMyInfluenceToConfigReducerInitialState = (args, callback) => {
    const { shopMyInfluence } = BrowserDatabase.getItem('config') || { shopMyInfluence: {} };

    return {
        ...callback(...args),
        shopMyInfluence
    };
};

export const addShopMyInfluenceToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { shopMyInfluence } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        shopMyInfluence
    };
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            handle_syncCartWithBEError
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addShopMyInfluenceConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addShopMyInfluenceToConfigReducerInitialState
    },
    'PeggysagePwa/Store/Config/Reducer/ConfigReducer': {
        function: addShopMyInfluenceToConfigUpdate
    }
};
