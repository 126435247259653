/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import moment from 'moment';
import { roundPrice } from 'Util/Price';

import Event, { EVENT_SHOPMYINFLUENCE_PURCHASE } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

export const PURCHASE_EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 0;

/**
 * On order success page "Purchase"
 */
export class Purchase extends BaseEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = PURCHASE_EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_SHOPMYINFLUENCE_PURCHASE, ({ orderID: orderId, totals}) => {
            this.handle(
                orderId,
                totals
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler(orderId, totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const { coupon_code = '', grand_total, tax_amount } = totals;
        const smic = (localStorage.getItem('smiclickId') === null || localStorage.getItem('smiclickId') === undefined) ? "" : localStorage.getItem('smiclickId');
        const amount = roundPrice(grand_total  - tax_amount);
        const smi_cart = this.getProducts(totals);

        const image = document.createElement("img"); image.width = 1; image.height = 1;
        image.src = `https://us-central1-shopmyinfluens.cloudfunctions.net/pixel/img?bid=-OB9ENFKcxFx0fV7sFKD&smic=${smic}&a=${amount}&cr=EUR&ref=${orderId}&vc=${encodeURIComponent(coupon_code)}&smi_cart=${smi_cart}`;
        document.getElementsByTagName("body")[0].appendChild(image);
    }

    /**
     * Get product detail
     *
     * @param totals
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, category: string, brand: string, url: string}[]}
     * @param cartData
     */
    getProducts({ items }) {
        const products = items.reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getItemData(item),
                    quantity: parseInt(item.quantity, 10) || 0
                }
            ]
        ), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => products.push(data));
        return JSON.stringify(products);
    }
}

export default Purchase;
