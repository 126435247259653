/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route } from 'react-router-dom';

import Meta from 'Component/Meta';
import { CUSTOMER_TYPES } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import { COLLECTIONS_PAGE_URL } from 'Route/CollectionsPage/CollectionsPage.config';
import { CONTACT_PAGE_URL } from 'Route/ContactPage/ContactPage.config';
import { DOCUMENT_LIST_PAGE_URL } from 'Route/DocumentListPage/DocumentListPage.config';
import { INTERACTIVE_CATALOG_PAGE_URL } from 'Route/InteractiveCatalogPage/InteractiveCatalogPage.config';
import {
    ACCOUNT_CUSTOMER_SERVICE_ISSUE_URL,
    ACCOUNT_CUSTOMER_SERVICE_URL,
    ACCOUNT_DOCUMENTS_URL,
    ACCOUNT_ORDER_FAVORITES_URL,
    ACCOUNT_QUICK_ORDER_URL
} from 'Route/MyAccount/MyAccount.config';
import { WISHLIST_PAGE_URL } from 'Route/NewWishlist/NewWishlist.config';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import {
    CUSTOMER_SERVICE, MY_DOCUMENTS, MY_ORDERS, QUICK_ORDER
} from 'Type/Account.type';
import history from 'Util/History';

import FavoriteShop from '../../route/FavoriteShop';
import NewWishlist from '../../route/NewWishlist';
import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    CHECKOUT,
    COLLECTIONS,
    CONTACT_PAGE,
    CONTENT_TOP_PROMO_BANNER,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_EMPLOYEE,
    CREATE_ACCOUNT_PRO,
    CREATE_ACCOUNT_STUDENT,
    DOCUMENT_LIST,
    FAQ,
    FOOTER,
    GDPR_POPUP,
    HELPDESK_EXTERNAL,
    INTERACTIVE_CATALOG,
    LOGIN_AS_CUSTOMER,
    LOGIN_PRO,
    LOGIN_STUDENT,
    MY_ACCOUNT_BANNER,
    MY_ACCOUNT_ORDERS_FAVORITES, MY_ACCOUNT_QUICK_ORDER,
    NEWS,
    PAYTWEAK_ORDER_CONSENT,
    PRODUCTALERTSUBSUBSCRIBE,
    SAGA,
    SHARED_WISHLIST,
    SWITCH_ITEMS_TYPE,
    TOP_PAGE_PROMO_BANNER,
    TUTOS
} from './Router.config';

import './Router.override.style';

export const ClientIssue = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "faq" */ 'Component/ClientIssue'));
export const FaqPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "faq" */ 'Route/FaqPage'));
export const SagaPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/SagaPage'));
export const NewsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/NewsPage'));
export const LoginAccountProPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "login" */ 'Route/LoginAccountPro'));
export const LoginAccountStudentPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "login" */ 'Route/LoginAccountStudent'));
export const CollectionsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "collections" */ 'Route/CollectionsPage'));
export const TopPagePromoBanner = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/TopPagePromoBanner'));
export const ContentTopPromoBanner = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/ContentTopPromoBanner'));
export const TutosPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/TutosPage'));
export const InteractiveCatalogPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "catalogues" */ 'Route/InteractiveCatalogPage'));
export const MyAccountBanner = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Component/MyAccountBanner'));
export const GdprPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/GdprPopup'));
export const PaytweakOrder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/PaytweakOrder'));
export const DocumentListPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "documents" */ 'Route/DocumentListPage'));
export const LoginAsCustomer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "login" */ 'Route/LoginAsCustomer'));
export const HelpdeskExternalPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "helpdesk" */ 'Route/HelpdeskExternalPage'));
export const ProductAlertsUnsubscribe = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "productalertsunsubscribe" */ 'Route/ProductAlertsUnsubscribe'));
export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    withStoreRegex
};

/** @namespace PeggysagePwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    static propTypes = {
        ...SourceRouter.propTypes,
        tutorialsPageUrl: PropTypes.string.isRequired
    };

    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        {
            component: <TopPagePromoBanner />,
            position: 5,
            name: TOP_PAGE_PROMO_BANNER
        },
        {
            component: <ContentTopPromoBanner />,
            position: 27, // before breadcrumbs
            name: CONTENT_TOP_PROMO_BANNER
        },
        {
            component: <MyAccountBanner />,
            position: 28, // before breadcrumbs
            name: MY_ACCOUNT_BANNER
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component: <Route path={ withStoreRegex('/checkout/:step?/:customerStep?/:accountStep?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 54, // before 55 (original route)
            name: CHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ (props) => <CreateAccountPage { ...props } customerType={ CUSTOMER_TYPES.private } /> } />,
            position: 59, // before 61 (original route)
            name: CREATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex(CONTACT_PAGE_URL) } render={ (props) => <ContactPage { ...props } /> } />,
            position: 81, // before 82 (original route)
            name: CONTACT_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/pro/account/login/') } render={ (props) => <LoginAccountProPage { ...props } /> } />,
            position: 66,
            name: LOGIN_PRO
        },
        {
            component: <Route path={ withStoreRegex(ACCOUNT_CUSTOMER_SERVICE_URL) } render={ (props) => <MyAccount { ...props } selectedTab={ CUSTOMER_SERVICE } /> } />,
            position: 90
        },
        {
            component: <Route path={ withStoreRegex(`${ACCOUNT_CUSTOMER_SERVICE_ISSUE_URL}:ticketId`) } render={ (props) => <MyAccount { ...props } selectedTab={ CUSTOMER_SERVICE } /> } />,
            position: 95
        },
        {
            component: <Route path={ withStoreRegex(ACCOUNT_DOCUMENTS_URL) } render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOCUMENTS } /> } />,
            position: 96
        },
        {
            component: <Route path={ withStoreRegex('/student/account/login/') } render={ (props) => <LoginAccountStudentPage { ...props } /> } />,
            position: 67,
            name: LOGIN_STUDENT
        },
        {
            component: <Route path={ withStoreRegex('/pro/account/create/:accountStep?/:uploadStep?') } render={ (props) => <CreateAccountPage { ...props } customerType={ CUSTOMER_TYPES.pro } /> } />,
            position: 68,
            name: CREATE_ACCOUNT_PRO
        },
        {
            component: <Route path={ withStoreRegex('/student/account/create/:accountStep?/:uploadStep?') } render={ (props) => <CreateAccountPage { ...props } customerType={ CUSTOMER_TYPES.student } /> } />,
            position: 69,
            name: CREATE_ACCOUNT_STUDENT
        },
        {
            component: <Route path={ withStoreRegex('/employee/account/create/:accountStep?/:uploadStep?') } render={ (props) => <CreateAccountPage { ...props } customerType={ CUSTOMER_TYPES.employee } /> } />,
            position: 69,
            name: CREATE_ACCOUNT_EMPLOYEE
        },
        {
            component: <Route path={ withStoreRegex(`${WISHLIST_PAGE_URL}/shared/:code`) } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 69, // before 81 (original route) & 70 (standard wishlist)
            name: SHARED_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex(WISHLIST_PAGE_URL) } render={ (props) => <NewWishlist { ...props } /> } />,
            position: 70
        },
        {
            component: <Route path={ withStoreRegex('/favorite/shop') } render={ (props) => <FavoriteShop { ...props } /> } />,
            position: 71
        },
        {
            component: <Route path={ withStoreRegex('/saga') } render={ (props) => <SagaPage { ...props } /> } />,
            position: 200,
            name: SAGA
        },
        {
            component: <Route path={ withStoreRegex('/faq/:categoryId?/:faqId?') } render={ (props) => <FaqPage { ...props } /> } />,
            position: 210,
            name: FAQ
        },
        {
            component: <Route path={ withStoreRegex('/news/:newsId') } render={ (props) => <NewsPage { ...props } /> } />,
            position: 220,
            name: NEWS
        },
        {
            component: <Route path={ withStoreRegex(COLLECTIONS_PAGE_URL) } render={ (props) => <CollectionsPage { ...props } /> } />,
            position: 230,
            name: COLLECTIONS
        },
        {
            component: <Route path={ withStoreRegex(this.props.tutorialsPageUrl) } render={ (props) => <TutosPage { ...props } /> } />,
            position: 240,
            name: TUTOS
        },
        {
            component: <Route path={ withStoreRegex(INTERACTIVE_CATALOG_PAGE_URL) } render={ (props) => <InteractiveCatalogPage { ...props } /> } />,
            position: 250,
            name: INTERACTIVE_CATALOG
        },
        {
            component: <Route path={ withStoreRegex(ACCOUNT_ORDER_FAVORITES_URL) } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 71,
            name: MY_ACCOUNT_ORDERS_FAVORITES
        },
        {
            component: <Route path={ withStoreRegex(ACCOUNT_QUICK_ORDER_URL) } render={ (props) => <MyAccount { ...props } selectedTab={ QUICK_ORDER } /> } />,
            position: 400,
            name: MY_ACCOUNT_QUICK_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/paytweak/order/consent/id/:orderId') } render={ (props) => <PaytweakOrder { ...props } /> } />,
            position: 300,
            name: PAYTWEAK_ORDER_CONSENT
        },
        {
            component: <Route path={ withStoreRegex(`${DOCUMENT_LIST_PAGE_URL}/:categoryId?`) } render={ (props) => <DocumentListPage { ...props } /> } />,
            position: 500,
            name: DOCUMENT_LIST
        },
        {
            component: <Route path={ withStoreRegex('/loginascustomer/login/index/') } render={ (props) => <LoginAsCustomer { ...props } /> } />,
            position: 550,
            name: LOGIN_AS_CUSTOMER
        },
        {
            component: <Route path={ withStoreRegex('/misc/footer') } render={ (props) => <Footer { ...props } isEmbed /> } />,
            position: 600,
            name: FOOTER
        },
        {
            component: <Route path={ withStoreRegex('/helpdesk/ticket/external/id/:ticketId') } render={ (props) => <HelpdeskExternalPage { ...props } /> } />,
            position: 650,
            name: HELPDESK_EXTERNAL
        },
        {
            component: <Route path={ withStoreRegex('/productalert/unsubscribe/:action') } render={ (props) => <ProductAlertsUnsubscribe { ...props } /> } />,
            position: 660,
            name: PRODUCTALERTSUBSUBSCRIBE
        }
    ];

    [AFTER_ITEMS_TYPE] = [
        ...this[AFTER_ITEMS_TYPE],
        {
            component: <GdprPopup />,
            position: 30,
            name: GDPR_POPUP
        }
    ];

    /**
     * Override: remove BEFORE_ITEMS_TYPE if route is isOnlyMainItems (instead of only removing AFTER_ITEMS_TYPE
     * @return {JSX.Element}
     */
    render() {
        const { isOnlyMainItems } = this.props;

        return (
            <>
                <Meta />
                <ReactRouter history={ history }>
                    { !isOnlyMainItems && this.renderSectionOfType(BEFORE_ITEMS_TYPE) }
                    <Suspense fallback={ this.renderFallbackPage(true) }>
                        { this.renderRouterContent() }
                    </Suspense>
                </ReactRouter>
            </>
        );
    }
}

export default RouterComponent;
