/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * ShopMyInfluence scripts
 */
export class Scripts {
    static getScript() {
        return 'https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/Pixel%2FclickObserver.js?alt=media';
    }
}

export default Scripts;
