/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class ShopMyInfluenceQuery {
    _getShopMyInfluenceConfigurationFields = () => ([
        'enabled',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'shopmyinfluence_general_init',
        'shopmyinfluence_purchase'
    ]);

    getShopMyInfluenceConfiguration = () => new Field('getShopMyInfluence')
        .setAlias('shopMyInfluence')
        .addFieldList(this._getShopMyInfluenceConfigurationFields());
}

export default new ShopMyInfluenceQuery();
